import { usePostTrackEventWithParams } from "./analyticsQueries"

export type TCsvExportEventTypes =
  | "Export Device Data Initiated"
  | "Export Device Data Confirmed"

function usePostCsvExportTrackingEvent<TEventProperties>(
  event: TCsvExportEventTypes
) {
  return usePostTrackEventWithParams<TCsvExportEventTypes, TEventProperties>({
    event,
  })
}

type TCsvExportConfirmedEventProperties = {
  type: "sound" | "temperature" | "humidity" | "motion"
}
export function usePostcsvExportConfirmed() {
  return usePostCsvExportTrackingEvent<TCsvExportConfirmedEventProperties>(
    "Export Device Data Confirmed"
  )
}

export function usePostcsvExportInitiated() {
  const h = usePostCsvExportTrackingEvent("Export Device Data Initiated")
  return {
    ...h,
    mutate: () => h.mutate({}),
  }
}
