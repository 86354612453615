import { useState } from "react"
import styled from "styled-components"

import { endOfDay, subMonths } from "date-fns"

import { UpgradeIconButton } from "src/components/FeatureBlockers/UpgradeIconButton"
import {
  TGraphData,
  useGetGraphData,
} from "src/components/Homes/DeviceDetails/Overview/useGetGraphData"
import { CreateNoiseIncidentReportButton } from "src/components/Reports/CreateNoiseIncidentReportButton"
import { IncidentReportDialogContainer } from "src/components/Reports/IncidentReportDialogContainer"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { THome } from "src/data/homes/types/homeTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { getTemperatureUnitWithFallback } from "src/data/user/logic/userTemperature"
import { useFlags } from "src/hooks/useFlags"
import { useTranslate } from "src/i18n/useTranslate"
import { mColors } from "src/ui/colors"
import { DateRangePicker } from "src/ui/DateRangePicker/DateRangePicker"
import { Divider } from "src/ui/Divider/Divider"
import { ITimeScopedThreshold } from "src/ui/Graphs/configurationUtils"
import { LineChart } from "src/ui/Graphs/LineChart"
import { MText } from "src/ui/MText"
import { Show } from "src/ui/Show/Show"
import { spacing } from "src/ui/spacing"
import { formatTemperatureUnitString } from "src/utils/l10n"

import { GraphCard } from "./GraphCard"
import { MotionGraphCard } from "./MotionGraphCard"

export type IGraphDateRange = {
  startDate: Date
  endDate: Date
}

export function DeviceGraphs({
  device,
  home,
  dateRange,
  instantlyTurnOnAlarm,
  hideGraphBorder = false,
}: {
  device: TDevice
  home: THome
  dateRange: IGraphDateRange
  instantlyTurnOnAlarm: boolean | null
  hideGraphBorder?: boolean
}) {
  const { sound, motion, humidity, temperature } = useGetGraphData({
    dateRange,
    device,
    home,
  })

  return (
    <GraphsBox>
      <MotionGraphCard
        hideGraphBorder={hideGraphBorder}
        timezone={home.timezone}
        data={motion.data}
        instantlyTurnOnAlarm={instantlyTurnOnAlarm}
        hidden={motion.hidden}
      ></MotionGraphCard>

      <NoiseGraphCard
        data={sound.data}
        hideGraphBorder={hideGraphBorder}
        timezone={home.timezone}
        thresholds={sound.thresholds}
        homeId={home.home_id}
        startDate={dateRange.startDate}
      />

      <TemperatureGraphCard
        data={temperature.data}
        timezone={home.timezone}
        hideGraphBorder={hideGraphBorder}
        yPlotLines={temperature.thresholds}
      />

      <HumidityGraphCard
        data={humidity.data}
        timezone={home.timezone}
        hideGraphBorder={hideGraphBorder}
        yPlotLines={humidity.thresholds}
      />

      <MouldRiskGraphCard
        timezone={home.timezone}
        hideGraphBorder={hideGraphBorder}
        device={device}
        home={home}
      />
    </GraphsBox>
  )
}

const GraphsBox = styled.div`
  display: grid;
  gap: ${spacing.M};
`

type LineChartCardProps = {
  data: TGraphData
  loading?: boolean
  thresholds?: ITimeScopedThreshold[]
  yPlotLines?: number[]
  timezone: THome["timezone"]
  hideGraphBorder: boolean
  hidden?: boolean
}

function NoiseGraphCard({
  data,
  loading,
  hideGraphBorder,
  timezone,
  hidden,
  thresholds,
  homeId,
  startDate,
}: LineChartCardProps & { homeId: string; startDate: Date }) {
  const { t, langKeys } = useTranslate()
  const user = useGetUser()
  const noiseIncidentFeature = useFeatureAvailability({
    feature: "reports_incident",
  })
  const showUpgradeButton = !noiseIncidentFeature.available

  const unitSymbol = `dB`
  const title = `${t(langKeys.sound_noise_monitoring)} (${unitSymbol})`

  return (
    <GraphCard
      title={title}
      isLoading={loading}
      hideGraphBorder={hideGraphBorder}
      hidden={hidden}
    >
      <NoiseCardInnerBox>
        {data && (
          <LineChart
            data={data}
            tooltip={{ unit: ` ${unitSymbol}`, decimals: 0 }}
            timezone={timezone}
            clockType={user.clock_type}
            thresholds={thresholds}
          />
        )}

        <Show if={!!data}>
          <Divider />

          <FlexBox>
            <div>
              <MText variant="subtitle" as="span">
                {t(langKeys.noise_graph_incident_report_body)}
              </MText>{" "}
              <UpgradeIconButton
                context={"incident_reports"}
                hidden={!showUpgradeButton}
              />
            </div>

            <CreateNoiseIncidentReportButton
              variant="secondary"
              size="small"
              prefillData={{ homeId, date: startDate }}
            />
            <IncidentReportDialogContainer context="device_page" />
          </FlexBox>
        </Show>
      </NoiseCardInnerBox>
    </GraphCard>
  )
}

const NoiseCardInnerBox = styled.div`
  display: grid;
  gap: ${spacing.XL};
`

const FlexBox = styled.div`
  display: flex;
  gap: ${spacing.XS};
  align-items: center;
  justify-content: space-between;
`

function TemperatureGraphCard({
  data,
  hidden,
  loading,
  hideGraphBorder,
  yPlotLines,
  timezone,
}: LineChartCardProps) {
  const { t, langKeys } = useTranslate()
  const user = useGetUser()
  const temperatureUnit = getTemperatureUnitWithFallback(user)
  const unitSymbol = formatTemperatureUnitString(temperatureUnit)
  const title = `${t(langKeys.temperature)} (${unitSymbol})`

  return (
    <GraphCard
      title={title}
      isLoading={loading}
      hideGraphBorder={hideGraphBorder}
      hidden={hidden}
    >
      {data && (
        <LineChart
          data={data}
          tooltip={{ unit: ` ${unitSymbol}`, decimals: 1 }}
          timezone={timezone}
          clockType={user.clock_type}
          // MON-454: Add support for temperature graph thresholds
          yPlotLines={yPlotLines}
        />
      )}
    </GraphCard>
  )
}

function HumidityGraphCard({
  data,
  loading,
  hideGraphBorder,
  timezone,
  yPlotLines,
  hidden,
}: LineChartCardProps) {
  const { t, langKeys } = useTranslate()
  const user = useGetUser()
  const unitSymbol = `%`
  const title = `${t(langKeys.humidity)} (${unitSymbol})`

  return (
    <GraphCard
      title={title}
      isLoading={loading}
      hideGraphBorder={hideGraphBorder}
      hidden={hidden}
    >
      {data && (
        <LineChart
          data={data}
          tooltip={{ unit: ` ${unitSymbol}`, decimals: 0 }}
          timezone={timezone}
          clockType={user.clock_type}
          yPlotLines={yPlotLines}
        />
      )}
    </GraphCard>
  )
}

type MoldRiskGraphCardProps = Omit<
  LineChartCardProps,
  "data" | "loading" | "hidden"
>

function MouldRiskGraphCard({
  hideGraphBorder,
  timezone,
  device,
  home,
}: MoldRiskGraphCardProps & { device: TDevice; home: THome }) {
  const { showMouldRiskGraph } = useFlags()
  const user = useGetUser()
  const { t, langKeys } = useTranslate()

  const [dateRange, setDateRange] = useState({
    startDate: subMonths(new Date(), 3),
    endDate: new Date(),
  })

  const { mouldRisk } = useGetGraphData({
    dateRange,
    device,
    home,
  })

  // This dynamically determines tick positions for the mould risk scale:
  // - Default scale is 0-3 to represent low to moderate risk.
  // - If the highest mould risk value exceeds 3, the scale expands to
  // include up to that value + 1 to ensure that the scale is adaptive, showing only relevant risk levels.
  function getTickPositions() {
    const highRiskIndex = 3
    const tickPositions = [0, 1, 2, 3, 4, 5, 6]

    const maxMouldRiskValue = Math.max(
      ...(mouldRisk.data?.map((d) => d.value || 0) || [])
    )

    const threshold =
      maxMouldRiskValue <= highRiskIndex ? highRiskIndex : maxMouldRiskValue + 1

    return tickPositions.filter((tick) => tick <= threshold)
  }

  return (
    <GraphCard
      title={
        <MoldRiskTitle dateRange={dateRange} setDateRange={setDateRange} />
      }
      isLoading={mouldRisk.isLoading}
      hideGraphBorder={hideGraphBorder}
      hidden={!showMouldRiskGraph}
    >
      {mouldRisk.data && (
        <LineChart
          data={mouldRisk.data}
          tooltip={{ unit: "", decimals: 3 }}
          timezone={timezone}
          clockType={user.clock_type}
          step="center"
          smooth={false}
          yAxisOptions={{
            tickInterval: 0.5,
            tickPositions: [...getTickPositions()],
            labels: {
              style: {
                fontFamily: "'Figtree', sans-serif",
                fontSize: "12px",
                color: mColors.textTertiary,
              },
            },
            plotBands: [
              {
                from: 0,
                to: 1,
                color: `${mColors.systemGoodLight}50`,
                label: {
                  text: t(langKeys.risk_of_mold_low),
                  style: {
                    fontFamily: "'Figtree', sans-serif",
                    fontSize: "12px",
                    color: mColors.textTertiary,
                  },
                },
              },
              {
                from: 1,
                to: 2,
                color: `${mColors.systemWarningLight}50`,
                label: {
                  text: t(langKeys.risk_of_mold_medium),
                  style: {
                    fontFamily: "'Figtree', sans-serif",
                    fontSize: "12px",
                    color: mColors.textTertiary,
                  },
                },
              },
              {
                from: 2,
                to: 3,
                color: `${mColors.systemErrorLight}50`,
                label: {
                  text: t(langKeys.risk_of_mold_high),
                  style: {
                    fontFamily: "'Figtree', sans-serif",
                    fontSize: "12px",
                    color: mColors.textTertiary,
                  },
                },
              },
              {
                from: 3,
                to: 6,
                color: `${mColors.systemErrorLight}50`,
              },
            ],
          }}
        />
      )}
    </GraphCard>
  )
}

function MoldRiskTitle({
  dateRange,
  setDateRange,
}: {
  dateRange: IGraphDateRange
  setDateRange: (dateRange: IGraphDateRange) => void
}) {
  const { t, langKeys } = useTranslate()
  const title = t(langKeys.risk_of_mold)

  function handleDateRangeChange({ startDate, endDate }: IGraphDateRange) {
    setDateRange({ startDate, endDate: endOfDay(endDate) })
  }

  return (
    <MoldRiksTitleBox>
      <div>{title}</div>

      <DateRangePicker
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        onDateRangeChange={handleDateRangeChange}
        dateRangeProps={{
          minBookingDays: 7,
        }}
        defaultPresetKey="WEEK"
        calendarPlacement="top-end"
      />
    </MoldRiksTitleBox>
  )
}

const MoldRiksTitleBox = styled.div`
  display: flex;
  gap: ${spacing.M};
  align-items: center;
  justify-content: space-between;
`

export const MotionGraphTitle = styled.div`
  display: flex;
  gap: ${spacing.XS};
`
