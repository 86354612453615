import { useMemo } from "react"

import { fromUnixTime, getUnixTime } from "date-fns"

import { IGraphDateRange } from "src/components/Homes/DeviceDetails/Overview/DeviceGraphs"
import {
  useFetchDeviceMotionReadings,
  useFetchDeviceReadings,
} from "src/data/devices/queries/deviceQueries"
import {
  DeviceReadingType,
  IDeviceReadings,
  TDevice,
} from "src/data/devices/types/deviceTypes"
import { useBackendFlags } from "src/data/flags/useBackendFlags"
import { monitoringAvailable } from "src/data/homes/logic/homeUtil"
import { MonitoringType, THome } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchNoiseMonitoringPreset } from "src/data/profileSettings/queries/monitoringPresetQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { getTemperatureUnitWithFallback } from "src/data/user/logic/userTemperature"
import { useFlags } from "src/hooks/useFlags"
import {
  extractActiveNoiseThreshold,
  extractHumidityThreshold,
  extractPresetNoiseThreshold,
  extractTemperatureThreshold,
} from "src/ui/Graphs/configurationUtils"
import { TLineChartData } from "src/ui/Graphs/graphTypes"
import { getMotionResolution, getResolution } from "src/ui/Graphs/utils"
import { toFahrenheit, utcToHomeTimezone } from "src/utils/l10n"

export function useGetGraphData({
  dateRange,
  device,
  home,
}: {
  dateRange: IGraphDateRange
  device: TDevice
  home: THome
}) {
  const { noise_profiles_released: presets } = useBackendFlags()

  const user = useGetUser()
  const { showOutdoorMotionGraph, showMouldRiskGraph } = useFlags()
  const { orgId } = useOrganization()
  const motionBucketSize = getMotionResolution(dateRange)
  const temperatureUnit = getTemperatureUnitWithFallback(user)

  const fetchDeviceMotionReadings = useFetchDeviceMotionReadings({
    orgId,
    deviceId: device.device_id,
    includeMinMax: true,
    startAt: dateRange.startDate.toISOString(),
    endAt: dateRange.endDate.toISOString(),
    timeResolution: motionBucketSize,
  })

  const fetchDeviceReadingsSound = useFetchDeviceReadings(
    createDeviceReadingsRequestBody({
      orgId,
      type: "sound_level",
      dateRange,
      deviceId: device.device_id,
    })
  )

  const fetchDeviceReadingsTemp = useFetchDeviceReadings(
    createDeviceReadingsRequestBody({
      orgId,
      type: "temperature",
      dateRange,
      deviceId: device.device_id,
    })
  )

  const fetchDeviceMouldRiskReadings = useFetchDeviceReadings({
    ...createDeviceReadingsRequestBody({
      orgId,
      type: "mould_risk",
      dateRange,
      deviceId: device.device_id,
    }),
    options: {
      enabled: showMouldRiskGraph,
    },
  })

  const fetchDeviceReadingsHumidity = useFetchDeviceReadings(
    createDeviceReadingsRequestBody({
      orgId,
      type: "humidity",
      dateRange,
      deviceId: device.device_id,
    })
  )

  const fetchNoiseMonitoringPreset = useFetchNoiseMonitoringPreset({
    orgId,
    noisePresetId: home.noise_profile_id,
    enabled: !!home.noise_profile_id,
  })

  const soundData: TGraphData = useMemo(() => {
    const data = fetchDeviceReadingsSound.data

    if (!data) {
      return null
    }

    return data.values.map((dt) => ({
      ...dt,
      dateTimeUtc: dt.datetime,
    }))
  }, [fetchDeviceReadingsSound.data])

  const humidityData: TGraphData = useMemo(() => {
    const data = fetchDeviceReadingsHumidity.data

    if (!data) {
      return null
    }

    return data.values.map((dt) => ({
      ...dt,
      dateTimeUtc: dt.datetime,
    }))
  }, [fetchDeviceReadingsHumidity.data])

  const motionDataLegacy = useMemo(() => {
    const data = fetchDeviceMotionReadings.data

    return (
      data?.values?.map((dt): [number, number] => [
        getUnixTime(utcToHomeTimezone(fromUnixTime(dt[0]), home.timezone)),
        dt[1],
      ]) || []
    )
  }, [home.timezone, fetchDeviceMotionReadings.data])

  const mouldRiskData: TGraphData = useMemo(() => {
    const data = fetchDeviceMouldRiskReadings.data
    if (!data) {
      return null
    }

    return data.values.map((dt) => ({
      ...dt,
      dateTimeUtc: dt.datetime,
    }))
  }, [fetchDeviceMouldRiskReadings.data])

  const motionData = useMemo(() => {
    const data = fetchDeviceMotionReadings.data

    return data?.values || []
  }, [fetchDeviceMotionReadings.data])

  const temperatureData: TGraphData = useMemo(() => {
    const data = fetchDeviceReadingsTemp.data

    if (!data) {
      return null
    }

    return transformTemperatureValues({
      values: fetchDeviceReadingsTemp.data.values,
      temperatureUnit,
    })
  }, [fetchDeviceReadingsTemp.data, temperatureUnit])

  const showMotionGraph =
    showOutdoorMotionGraph ||
    monitoringAvailable({
      type: MonitoringType.MOTION,
      home,
      devices: [device],
    })

  const noiseThresholdConfig = device.placed_outdoors
    ? fetchNoiseMonitoringPreset.data?.outdoor_noise_threshold
    : fetchNoiseMonitoringPreset.data?.indoor_noise_threshold

  const activeNoiseThreshold = presets
    ? extractPresetNoiseThreshold({
        isNoiseMonitoringEnabled: true,
        noiseThresholdConfig,
      })
    : extractActiveNoiseThreshold(device.configuration, true)

  const temperatureThresholds = extractTemperatureThreshold(
    device.configuration,
    temperatureUnit
  )

  const humidityThresholds = extractHumidityThreshold(device.configuration)

  return {
    sound: {
      isLoading:
        fetchDeviceReadingsSound.isLoading ||
        fetchNoiseMonitoringPreset.isInitialLoading,
      data: soundData,
      thresholds: activeNoiseThreshold,
    },
    temperature: {
      isLoading: fetchDeviceReadingsTemp.isLoading,
      data: temperatureData,
      unit: temperatureUnit,
      thresholds: temperatureThresholds,
    },
    humidity: {
      isLoading: fetchDeviceReadingsHumidity.isLoading,
      data: humidityData,
      thresholds: humidityThresholds,
    },
    motion: {
      isLoading: fetchDeviceMotionReadings.isLoading,
      dataLegacy: motionDataLegacy,
      data: motionData,
      hidden: !showMotionGraph,
      bucketSize: motionBucketSize,
    },
    mouldRisk: {
      isLoading: fetchDeviceMouldRiskReadings.isLoading,
      data: mouldRiskData,
      hidden: !showMouldRiskGraph,
    },
  }
}

function createDeviceReadingsRequestBody({
  orgId,
  type,
  dateRange,
  deviceId,
}: {
  orgId: string
  type: DeviceReadingType
  dateRange: IGraphDateRange
  deviceId: TDevice["device_id"]
}) {
  return {
    orgId,
    type,
    deviceId,
    includeMinMax: true,
    startAt: dateRange.startDate.toISOString(),
    endAt: dateRange.endDate.toISOString(),
    timeResolution: getResolution(dateRange),
  }
}

function transformTemperatureValues({
  values,
  temperatureUnit,
}: {
  values: IDeviceReadings["values"]
  temperatureUnit: "C" | "F"
}) {
  function convertTemperature(value: number | undefined) {
    if (value === undefined) {
      return undefined
    }

    if (temperatureUnit === "C") {
      return value
    }

    return Number(toFahrenheit(value, 2))
  }

  return values.map((value) => ({
    ...value,
    value: convertTemperature(value.value),
    min: convertTemperature(value.min),
    max: convertTemperature(value.max),
    dateTimeUtc: value.datetime,
  }))
}

export type TGraphData = TLineChartData[] | null
